import { getIsBrandPreview } from "@faire/retailer-visitor-shared/lib/getIsBrandPreview";
import { getSettingValueWithoutAssignment } from "@faire/retailer-visitor-shared/settings/__internal__/getSetting";
import {
  useAssignSetting,
  useSetting,
} from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115 } from "@faire/web-api--source/indigofair/settings/ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115";
import { ENABLE_UNLISTED_PRODUCTS_REQUESTS_IN_BRAND_ORDERS_250115 } from "@faire/web-api--source/indigofair/settings/ENABLE_UNLISTED_PRODUCTS_REQUESTS_IN_BRAND_ORDERS_250115";

export const useSettingEnableUnlistedProductsRequests = (): boolean =>
  useSetting(ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115, false);

export const getSettingEnableUnlistedProductsRequests = (): boolean =>
  getIsBrandPreview()
    ? getSettingValueWithoutAssignment(
        ENABLE_UNLISTED_PRODUCTS_REQUESTS_IN_BRAND_ORDERS_250115,
        false
      )
    : getSettingValueWithoutAssignment(
        ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115,
        false
      );

export const useAssignSettingEnableUnlistedProductsRequests = () =>
  useAssignSetting(ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115);
