import { SettingConst } from '@faire/web-api--source/types';

/**
 * Enables brands to see orders with unlisted products requests in the brand orders page
 */
export const ENABLE_UNLISTED_PRODUCTS_REQUESTS_IN_BRAND_ORDERS_250115 =
  'ENABLE_UNLISTED_PRODUCTS_REQUESTS_IN_BRAND_ORDERS_250115' as SettingConst<
    'ENABLE_UNLISTED_PRODUCTS_REQUESTS_IN_BRAND_ORDERS_250115',
    boolean,
    'MAKER' | 'RETAILER' | 'HOTJAR_BRAND' | 'VISITOR_WEB'
  >;
