import { SettingConst } from '@faire/web-api--source/types';

/**
 * Enables retailers to request unlisted products from Brands in carts
 */
export const ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115 =
  'ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115' as SettingConst<
    'ENABLE_UNLISTED_PRODUCTS_REQUESTS_250115',
    boolean,
    'RETAILER' | 'HOTJAR_RETAILER' | 'VISITOR_WEB' | 'HOTJAR_VISITOR_WEB'
  >;
