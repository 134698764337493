/* eslint-disable @faire/ssr-no-restricted-globals */
export function setTimeoutIfPossible(
  handler: TimerHandler,
  timeout?: number,
  // eslint-disable-next-line
  ...argumentsArray: any[]
): number | undefined {
  if (typeof window === "undefined") {
    throw new Error("Next.js doesn't allow setTimeout");
  }
  return window.setTimeout(handler, timeout, argumentsArray);
}
